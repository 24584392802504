import $ from 'jquery';

export default class Theme {
	/**
	 Constructor
	 **/
	constructor() {
		this.build();
	}

	/**
	 Build page elements, plugins init
	 **/
	build() {
		this.toggleSubMenu();
		this.menuWithHash();
		//this.sliders();
	}


	/** Check for mobile device **/
	isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	stringToBoolean(string) {

		switch (string) {
			case "true":
			case "yes":
			case "1":
				return true;
			case "false":
			case "no":
			case "0":
			case null:
			case '':
				return false;
			default:
				return Boolean(string);
		}
	}

	/** Check email address **/
	isValidEmailAddress(emailAddress) {
		const pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
		return pattern.test(emailAddress);
	}

	// usage: setCookie('visitor_currency', 'USD', {secure: true, 'max-age': 3600});
	setCookie(name, value, options = {}) {

		const defaultPath = window.themeJsVars ?
			(window.themeJsVars.COOKIE_PATH ? window.themeJsVars.COOKIE_PATH : '/')
			: '/';

		const defaultDomain = window.themeJsVars ?
			(window.themeJsVars.COOKIE_DOMAIN ? window.themeJsVars.COOKIE_DOMAIN : '')
			: '';

		options = {
			path: defaultPath,
			domain: defaultDomain,
			...options
		};

		if (options.expires instanceof Date) {
			options.expires = options.expires.toUTCString();
		}

		let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

		for (let optionKey in options) {
			updatedCookie += "; " + optionKey;
			let optionValue = options[optionKey];
			if (optionValue !== true) {
				updatedCookie += "=" + optionValue;
			}
		}

		document.cookie = updatedCookie;
	}


	getCookie(name) {
		let matches = document.cookie.match(
			new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)")
		);
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}


	deleteCookie(name) {
		this.setCookie(name, "", {'max-age': -1})
	}


	getGETParam(paramName) {
		let params = window
		.location
		.search
		.replace('?', '')
		.split('&')
		.reduce(
			function (p, e) {
				var a = e.split('=');
				p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
				return p;
			},
			{}
		);
		return params[paramName];
	}


	// example: window.DC.cssRuleUpdate('#menu a[href*="sidebar"]::after','content','"\u2192"','important');
	cssRuleUpdate(selector, property, value, important) {

		let setRuleProperty = function (rule, selector, property, value, important) {
			if (!rule instanceof CSSStyleRule || !rule.selectorText) {
				return false;
			}

			if (rule.selectorText && rule.selectorText == selector) {
				if (typeof important == 'undefined') {
					rule.style.setProperty(property, value);
				} else {
					rule.style.setProperty(property, value, 'important');
				}
				return true;
			}

			return false;
		};

		try {
			for (let i = 0; i < document.styleSheets.length; i++) {
				let ss = document.styleSheets[i];
				let r = ss.cssRules ? ss.cssRules : ss.rules;

				for (let j = 0; j < r.length; j++) {
					if (r[j] instanceof CSSStyleRule) {
						setRuleProperty(r[j], selector, property, value, important);
					}
					if (r[j] instanceof CSSMediaRule && r[j].cssRules) {
						for (let k = 0; k < r[j].cssRules.length; k++) {
							setRuleProperty(r[j].cssRules[k], selector, property, value, important);
						}
					}
				}
			}
		} catch (e) {
			if (e.name !== 'SecurityError') {
				console.log('Developer: ' + e);
			}
		}
	}


	toggleSubMenu() {
		const menuItems = document.querySelectorAll('.vp-primary-menu .menu-item-has-children');

		[].slice.call(menuItems).map(function (item) {

			let btn = item.querySelector('.submenu-toggle');
			if (btn instanceof HTMLElement) {
				btn.onclick = function (e) {
					e.preventDefault();
					item.classList.toggle('open-menu-item');
				}
			}

			let empryLink = item.querySelector('a[href="#"]');
			if (empryLink instanceof HTMLElement) {
				empryLink.onclick = function (e) {
					e.preventDefault();
					item.classList.toggle('open-menu-item');
				}
			}

		})
	};


	menuWithHash() {
		var scrollOffset = 155; // parseInt($('#header').outerHeight())

		var hash = window.location.hash;

		// if page URL contains one-page hash
		if (hash.indexOf('#!/') !== -1) {
			var linkParts = hash.split('#!/'),
				sectionID = linkParts[1],
				$section = $('#' + sectionID);

			if ($section.length) {

				setTimeout(function () {

					$('html, body').animate({
						scrollTop: parseInt($section.offset().top - scrollOffset)
					}, 800, function () {
						// Add hash (#) to URL when done scrolling (default click behavior)
						window.location.hash = '#!/' + sectionID;
						resolveMenuClass(window.location.hash);
					});

				}, 1000);

			}
		}


		// if one-page menu items click
		$('a[href*="#!/"]').click(function () {

			var $link = $(this),
				url = $link.attr('href');

			// is one page link
			if (url.indexOf('#!/') !== -1) {

				var linkParts = url.split('#!/'),
					sectionID = linkParts[1],
					$section = $('#' + sectionID);

				if ($section.length) {

					$('html, body').animate({
						scrollTop: parseInt($section.offset().top - scrollOffset)
					}, 800, function () {
						// Add hash (#) to URL when done scrolling (default click behavior)
						window.location.hash = '#!/' + sectionID;
						resolveMenuClass(window.location.hash);
					});

					$('body').removeClass('open-menu');

					return false;
				}
			}
		});


		function resolveMenuClass(hash) {
			$('.vp-primary-menu li').removeClass('current-menu-item');
			$('.vp-primary-menu a').each(function () {
				if ($(this).attr('href').indexOf(hash) !== -1) {
					$(this).parent().addClass('current-menu-item');
				}
			});
		}
	}


	sliders() {
		const $blogCarousel = $('.blog-carousel');

		if ($blogCarousel.length && $blogCarousel.slick) {

			$blogCarousel.on('init', function (event, slick) {
				setTimeout(function () {
					$blogCarousel.css({'visibility': 'visible'});
					$blogCarousel.off('init');
				}, 50);
			});

			$blogCarousel.slick({
				infinite: false,
				slidesToShow: 1.2,
				slidesToScroll: 1,
				mobileFirst: true,
				responsive: [{
					breakpoint: 417,
					settings: {
						slidesToShow: 2.2,
					}
				},
					{
						breakpoint: 885,
						settings: {
							slidesToShow: 4,
						}
					},
				]
			});
		}
	}
}
